import { memo } from "react";
import { componentsById } from "@src/cms-components";
import { IDynamicLayoutProps } from "./types";

function DynamicLayout({ data }: IDynamicLayoutProps) {
  const components = data?.version?.[0]?.components || [];

  return (
    <>
      {Array.isArray(components)
        ? components?.map((layout, index) => {
            const Component = componentsById[layout.id];
            if (Component) {
              return (
                <div
                  key={layout.id + index}
                  style={{
                    backgroundColor:
                      data?.page?.seoFields?.metadata?.[0]?.content ? data?.page?.seoFields?.metadata?.[0]?.content : "transparent"
                  }}
                >
                  <Component key={layout.id + index} {...layout.params} />
                </div>
              );
            } else {
              return null;
            }
          })
        : null}
    </>
  );
}

export default memo(DynamicLayout);
