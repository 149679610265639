import Head from "next/head";
import DynamicLayout from "@components/DynamicLayout";
import { requestLayoutData } from "@src/server/utils/layout";
import { LayoutData } from "@types";
import Script from "next/script";
import 'sentry.client.config'

type HomeProps = {
  layoutData: LayoutData;
};

export default function Home({ layoutData }: HomeProps) {
  return (
    <>
      <Head>
        <title>Epallet</title>
        <meta
          name="description"
          content="Shop at EPallet for the widest selection and best variety."
        />
        <meta name="viewport" content="width=device-width" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        {/* <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet" /> */}
        <Script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer',${process.env.NEXT_PUBLIC_GTM_ID});
          `}
        </Script>
      </Head>
      {/* <body>
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
      </body> */}
      <main style={{ padding: 0 }} role="main">
        <DynamicLayout data={layoutData} />
      </main>
    </>
  );
}

export async function getServerSideProps() {
  const timeTaken = new Date();
  try {
    const res = await requestLayoutData("/home");
    const data = (res && res.data && res.data.data) || {};

    return {
      props: {
        layoutData: { ...data, error: null },
      },
    };
  } catch (error) {
    const now = Date.now();
    const duration = now - timeTaken.getTime();
    console.error("Unable to load Home page", error, "duration:", duration);
    return {
      props: {
        layoutData: { error: error },
      },
    };
  }
}
